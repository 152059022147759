import {Box, MenuItem, TextField, Typography, Checkbox, Card} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, {useEffect, useRef, useState} from "react";
import TaskFrequencyOptions from "./TaskFrequencyOptions";
import {pluralise} from "../../../../helpers/text";

const MILLISECONDS_IN_HOUR = 60 * 60 * 1000;

const TaskFrequencySelector = ({ frequency, setFrequency }) => {
    const { startDate, intervalCount, intervalDateType, startIntervalOnCompletion } = frequency;

    const [dateError, setDateError] = useState(null);
    const [intervalCountError, setIntervalCountError] = useState(null)

    const intervalCountRef = useRef(null);
    useEffect(() => {
        intervalCountRef.current = intervalCount;
    }, [intervalCount]);

    const dateErrorMessage = React.useMemo(() => {
        switch (dateError) {
            case 'minDate': {
                return 'Your start date cannot be in the past';
            }

            case 'invalidDate': {
                return 'Please enter a valid date';
            }

            default: {
                return '';
            }
        }
    }, [dateError]);

    // On unmount, reset interval count to 1 if it is 0
    useEffect(() => {
        return () => {
            if(intervalCountRef.current <= 0) {
                setFrequency({
                    ...frequency,
                    intervalCount: 1
                })
            }
        }
    }, []);

    return (
        <Card className={"task-frequency-selector"} sx={{padding: 0}}>
            <h2 className="task-frequency-selector__title">Repeat task</h2>

            <Box className={"task-frequency-selector__contents"}>
                <Box className={"task-frequency-selector__start-date-container"}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            minTime={dayjs().startOf('day')}
                            renderInput={(props) =>
                                <TextField {...props} helperText={dateErrorMessage} />}
                            label={"Start Date"}
                            disablePast={true}
                            value={startDate}
                            onChange={(newStartDate) => {
                                if (dayjs(newStartDate) < dayjs()) {
                                    const currentTime = dayjs();
                                    const roundedTime = dayjs(Math.ceil(currentTime.valueOf() / MILLISECONDS_IN_HOUR ) * MILLISECONDS_IN_HOUR).startOf('day');
                                    setFrequency({
                                        ...frequency,
                                        startDate: roundedTime.valueOf()
                                    })
                                } else {
                                    setFrequency({
                                        ...frequency,
                                        startDate: newStartDate.startOf("day").valueOf()
                                    })
                                }
                            }}
                            onError={(newError) => {
                                setDateError(newError)}}
                            ampm={false}
                            inputFormat={"DD/MM/YYYY"}
                            views={['year', 'month', 'day']}
                        />
                    </LocalizationProvider>
                </Box>
                <Box className={"task-frequency-selector__interval-container"}>
                    <p className="task-frequency-selector__every-label">Every</p>
                    <TextField
                        type="number"
                        className="task-frequency-selector__interval-count-input"
                        value={intervalCount.toString()}
                        onChange={(event) => {
                            const newIntervalCount = event.target.value;
                            setFrequency({
                                ...frequency,
                                intervalCount: +newIntervalCount
                            })

                            if(newIntervalCount > 0) {
                                setIntervalCountError(null)
                            } else {
                                setIntervalCountError("Must be greater than 0")
                            }
                        }}
                    />
                    <TextField
                        select
                        className="task-frequency-selector__interval-type-input"
                        value={intervalDateType}
                        onChange={(event) => setFrequency({
                            ...frequency,
                            intervalDateType: event.target.value
                        })}
                    >
                        <MenuItem value="day">{pluralise("Day", intervalCount)}</MenuItem>
                        <MenuItem value="week">{pluralise("Week", intervalCount)}</MenuItem>
                        <MenuItem value="month">{pluralise("Month", intervalCount)}</MenuItem>
                        <MenuItem value="year">{pluralise("Year", intervalCount)}</MenuItem>
                    </TextField>
                </Box>
                {!startIntervalOnCompletion &&
                    <Box className={"task-frequency-selector__interval-container"}>
                        {(frequency.intervalDateType === "week" || frequency.intervalDateType === "month") && <p className="task-frequency-selector__every-label">On</p>}
                        <TaskFrequencyOptions frequency={frequency} setFrequency={setFrequency}/>
                    </Box>
                }
                {intervalCountError && <Typography className="task-frequency-selector__error-message">{intervalCountError}</Typography>}

                <Box className={"task-frequency-selector__checkbox-container"}>
                    <Checkbox
                      checked={startIntervalOnCompletion ?? false}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setFrequency({
                            ...frequency,
                            startIntervalOnCompletion: true
                          })
                        } else {
                          const { startIntervalOnCompletion: _, ...newFrequency } = frequency;

                          setFrequency(newFrequency)
                        }
                      }}
                    />
                    <Typography variant="body2" sx={{mb: 0}}>Start countdown after task is completed</Typography>
                </Box>
            </Box>
        </Card>
    )
}

export default TaskFrequencySelector;