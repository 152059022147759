import {Checkbox, FormControlLabel, IconButton, Popover, TextField} from "@mui/material";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import TaskFrequencySelector from "../taskInputs/recurringTasks/TaskFrequencySelector";
import {useMemo, useRef, useState} from "react";
import {dispatch} from "../../../redux/store";
import socket from "../../../socket";
import {removeRecurringTask, updateTaskFrequency} from "../../../redux/actions/recurringTasks";
import {setOverlayVisible} from "../../../redux/actions/overlay";

const RecurringTask = ({ task }) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const anchorRef = useRef(null);
    const { intervalCount, intervalDateType, startDate, additionalData } = task;

    const recurringLabelText = useMemo(() => {
        return `Every${intervalCount === 1 ? "" : ` ${intervalCount}`} ${intervalDateType}${intervalCount > 1 ? "s": ""}`;
    }, [intervalCount, intervalDateType]);

    return (
        <div className="recurring-task">
            <div className={`recurring-task_header`}>
                <h4 className={`recurring-task_title`}>
                    {task.name}
                </h4>
            </div>
            <div className={`recurring-task_body`}>
                <FormControlLabel
                    label=""
                    control={
                        <Checkbox
                            label="Recurring"
                            checked={true}
                            onChange={() => {
                                dispatch(setOverlayVisible({
                                    visible: true,
                                    overlayType: "confirm",
                                    confirmationText: "Do you want to stop this task from recurring?",
                                    confirmCallback: () => {
                                        dispatch(removeRecurringTask(task.taskId))
                                        socket.emit("setTaskRecurringFrequency", { taskId: parseInt(task.taskId), frequency: null });
                                        dispatch(setOverlayVisible({
                                            visible: false,
                                            overlayType: ""
                                        }));
                                    }
                                }));
                            }}
                        />
                    }
                />
                <TextField
                    label="Recurs"
                    ref={anchorRef}
                    variant="outlined"
                    onClick={() => {
                        setPopoverOpen(popoverOpen => !popoverOpen)
                    }}
                    value={recurringLabelText}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <IconButton className={"recurring-tasks-container_icon-button"}>
                                <EventRepeatIcon/>
                            </IconButton>
                        )
                    }}
                />
                <Popover
                    open={popoverOpen}
                    onClose={() => setPopoverOpen(false)}
                    anchorEl={anchorRef.current}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <TaskFrequencySelector
                        frequency={task}
                        setFrequency={(frequency) => {
                            dispatch(updateTaskFrequency(task.taskId, frequency));
                            socket.emit("setTaskRecurringFrequency", { taskId: parseInt(task.taskId), frequency });
                        }}/>
                </Popover>
            </div>
        </div>
    )
}

export default RecurringTask;