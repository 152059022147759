import { SET_RECURRING_TASKS, REMOVE_RECURRING_TASK, UPDATE_TASK_FREQUENCY} from "../constants/recurringTasks"

const initialValue = {
    recurringTasks: {}
};

export default (state = initialValue, action) => {
    const { type } = action;
    switch (type) {
        case SET_RECURRING_TASKS: {
            const { recurringTasks } = action;
            return {
                ...state,
                recurringTasks
            };
        }
        case REMOVE_RECURRING_TASK: {
            const { taskId } = action;
            const { recurringTasks } = state;
            delete recurringTasks[taskId];
            return {
                ...state,
                recurringTasks
            };
        }
        case UPDATE_TASK_FREQUENCY: {
            const { taskId, frequency } = action;
            const { recurringTasks } = state;
            const task = { ...recurringTasks[taskId] };

            return {
                ...state,
                recurringTasks: {
                    ...recurringTasks,
                    [taskId]: {
                        ...task,
                        ...frequency,
                        startIntervalOnCompletion: frequency.startIntervalOnCompletion ?? undefined
                    }
                }
            };
        }
        default:
            return state
    }
}