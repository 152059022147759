import {Box, MenuItem, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import dayjs from "dayjs";

const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const stringOrdinals = ["First", "Second", "Third", "Fourth", "Fifth"];

const isDateInPast = (date) => {
  return dayjs(date) < dayjs().startOf("day");
}

const getFirstDayOfMonthValue = () => {
  if (isDateInPast(dayjs().startOf("month"))) {
    return dayjs().startOf("month").add(1, "month").valueOf();
  }

  return dayjs().startOf("month").valueOf();
}

const getTimesDayHasOccurredInMonth = () => {
  const dayOfMonth = dayjs().date();

  return Math.ceil(dayOfMonth / 7);
}

const getDaysOfWeekValues = (dayOfWeek) => {
  return [
    dayjs().day(dayOfWeek > 0 ? 7 : 0).startOf("day").valueOf(),
    dayjs().day(dayOfWeek > 1 ? 8 : 1).startOf("day").valueOf(),
    dayjs().day(dayOfWeek > 2 ? 9 : 2).startOf("day").valueOf(),
    dayjs().day(dayOfWeek > 3 ? 10 : 3).startOf("day").valueOf(),
    dayjs().day(dayOfWeek > 4 ? 11 : 4).startOf("day").valueOf(),
    dayjs().day(dayOfWeek > 5 ? 12 : 5).startOf("day").valueOf(),
    dayjs().day(dayOfWeek > 6 ? 13 : 6).startOf("day").valueOf(),
  ]
}

const getOptions = (intervalDateType) => {
  const dayOfWeek = dayjs().day();
  const dayOfMonth = dayjs().date();

  switch (intervalDateType) {
    case "week": {
      const daysOfWeekValues = getDaysOfWeekValues(dayOfWeek);

      return daysOfWeekValues.map((dayOfWeekValue, index) => ({
          text: dayNames[index],
          value: dayOfWeekValue
        })
      )
    }
    case "month": {
      const timesDayHasOccurredInMonth = getTimesDayHasOccurredInMonth();
      const timesDayHasOccurredInMonthOption = {
        text: `${stringOrdinals[timesDayHasOccurredInMonth - 1]} ${dayNames[dayOfWeek]} of the month`,
        value: dayjs().startOf("day").valueOf(),
        additionalData: { dayOfWeek, timesOccurred: timesDayHasOccurredInMonth }
      }

      const dayOfMonthOption = ((dayOfMonth !== 1) && (dayOfMonth !== dayjs().endOf("month").date())) ?
        [{text: `${dayOfMonth} day of the month`, value: dayjs().startOf("day").valueOf()}]
        : []

      return [
        ...(dayOfMonthOption),
        {text: "First day of the month", value: getFirstDayOfMonthValue()},
        {text: "Last day of the month", value: dayjs().endOf("month").startOf("day").valueOf()},
        timesDayHasOccurredInMonthOption
      ]
    }
    default:
      return []
  }
}

const TaskFrequencyOptions = ({ frequency, setFrequency }) => {
  const [options, setOptions] = useState([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

  useEffect(() => {
    setOptions(getOptions(frequency.intervalDateType))
  }, [frequency.intervalDateType]);

  useEffect(() => {
    if (options.length) {
      let newIndex;

      if (frequency.additionalData) {
        newIndex = options.findIndex((option) =>
          option.additionalData?.dayOfWeek === frequency.additionalData.dayOfWeek &&
          option.additionalData?.timesOccurred === frequency.additionalData.timesOccurred);
      } else {
        newIndex = options.findIndex((option) => option.value === frequency.startDate);
      }

      setSelectedOptionIndex(newIndex);
    }
  }, [options]);

  const handleOnChange = (event) => {
    const { value, additionalData } = options[event.target.value];
    const { additionalData: _, ...frequencyNoData } = frequency;

    let newFrequency = {
      ...frequencyNoData,
      startDate: value
    }

    if (additionalData) {
      newFrequency = {
        ...newFrequency,
        additionalData
      }
    }

    setSelectedOptionIndex(event.target.value);
    setFrequency(newFrequency);
  }

  return (
    <>
      {!!options.length &&
        <Box className={"task-frequency-selector__options-container"}>
          <TextField
          select
          value={selectedOptionIndex > -1 ? selectedOptionIndex : "placeholder"}
          className={"task-frequency-selector__options-input"}
          onChange={handleOnChange}
          >
            <MenuItem value={"placeholder"} disabled>
              Select an option
            </MenuItem>
            {options.map((option, index) => (
              <MenuItem key={index} value={index}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      }
    </>
)
}

export default TaskFrequencyOptions;
